var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("main", [
    !_vm.$store.user.isSupplier
      ? _c("section", { staticClass: "cta" }, [
          _c("div", { staticClass: "cta__bg" }),
          _vm._v(" "),
          _c("div", { staticClass: "cta__center" }, [
            _c("h2", { staticClass: "cta__heading" }, [
              _vm._v("Find your next footwear supplier the easy way"),
            ]),
            _vm._v(" "),
            _c("h3", { staticClass: "cta__subheading" }, [
              _vm._v("\n        We search among\n        "),
              _c("strong", [
                _vm._v(_vm._s(_vm.$store.suppliers.counter) + " suppliers"),
              ]),
              _vm._v(
                " to find the best ones matching your specific needs -\n        at no cost!\n      "
              ),
            ]),
            _vm._v(" "),
            _c(
              "form",
              {
                staticClass: "cta__form",
                attrs: { action: "#", method: "post" },
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.findSuppliers(_vm.email)
                  },
                },
              },
              [
                !_vm.$store.user.loggedIn
                  ? _c(
                      "div",
                      { staticClass: "cta__input field__input-wrapper" },
                      [
                        _c(
                          "div",
                          { staticClass: "field__input-wrapper-inner" },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.email,
                                  expression: "email",
                                },
                              ],
                              staticClass: "field__input",
                              attrs: {
                                type: "email",
                                placeholder: "Enter your e-mail address...",
                                required: "",
                              },
                              domProps: { value: _vm.email },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.email = $event.target.value
                                },
                              },
                            }),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "fs-button-main",
                  {
                    staticClass: "cta__button",
                    attrs: {
                      type: "submit",
                      long: true,
                      size: _vm.$store.user.loggedIn ? "huge" : "large",
                      "input-like": !_vm.$store.user.loggedIn,
                      loading: _vm.ctaFormLoading,
                    },
                  },
                  [_vm._v("Start finding new suppliers")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            !_vm.$store.user.loggedIn
              ? _c("div", { staticClass: "cta__social" }, [
                  _c("span", { staticClass: "cta__social-label" }, [
                    _vm._v("Or sign in using"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "cta__social-buttons" }, [
                    _c(
                      "form",
                      {
                        staticClass: "cta__social-button",
                        attrs: {
                          method: "post",
                          action: "/api/account/externalLogin",
                        },
                      },
                      [
                        _c("input", {
                          attrs: {
                            type: "hidden",
                            name: "returnUrl",
                            value: "/quotation",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "link link--button",
                            attrs: {
                              type: "submit",
                              name: "provider",
                              value: "LinkedIn",
                              title: "Log in using your LinkedIn account",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "link__icon",
                              domProps: { innerHTML: _vm._s(_vm.svg.linkedIn) },
                            }),
                            _vm._v("LinkedIn\n            "),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "cta__bottom" }, [
            _c("div", { staticClass: "cta__bottom-logos" }, [
              _c("div", {
                staticClass: "cta__bottom-logo cta__bottom-logo--sneaky-steve",
                domProps: { innerHTML: _vm._s(_vm.svg.brandLogos.sneakySteve) },
              }),
              _vm._v(" "),
              _c("div", {
                staticClass: "cta__bottom-logo cta__bottom-logo--zalando",
                domProps: { innerHTML: _vm._s(_vm.svg.brandLogos.zalando) },
              }),
              _vm._v(" "),
              _c("div", {
                staticClass: "cta__bottom-logo cta__bottom-logo--nakd",
                domProps: { innerHTML: _vm._s(_vm.svg.brandLogos.nakd) },
              }),
              _vm._v(" "),
              _c("div", {
                staticClass: "cta__bottom-logo cta__bottom-logo--axel-arigato",
                domProps: { innerHTML: _vm._s(_vm.svg.brandLogos.axelArigato) },
              }),
              _vm._v(" "),
              _c("div", {
                staticClass: "cta__bottom-logo cta__bottom-logo--hm",
                domProps: { innerHTML: _vm._s(_vm.svg.brandLogos.hm) },
              }),
              _vm._v(" "),
              _c("div", {
                staticClass: "cta__bottom-logo cta__bottom-logo--ralph-lauren",
                domProps: { innerHTML: _vm._s(_vm.svg.brandLogos.ralphLauren) },
              }),
              _vm._v(" "),
              _c("div", {
                staticClass: "cta__bottom-logo cta__bottom-logo--relaxo",
                domProps: { innerHTML: _vm._s(_vm.svg.brandLogos.relaxo) },
              }),
              _vm._v(" "),
              _c("div", {
                staticClass: "cta__bottom-logo cta__bottom-logo--vera-bradley",
                domProps: { innerHTML: _vm._s(_vm.svg.brandLogos.veraBradley) },
              }),
              _vm._v(" "),
              _c("div", {
                staticClass: "cta__bottom-logo cta__bottom-logo--arkk",
                domProps: { innerHTML: _vm._s(_vm.svg.brandLogos.arkk) },
              }),
              _vm._v(" "),
              _c("div", {
                staticClass: "cta__bottom-logo cta__bottom-logo--eytys",
                domProps: { innerHTML: _vm._s(_vm.svg.brandLogos.eytys) },
              }),
            ]),
            _vm._v(" "),
            !_vm.$store.user.loggedIn || !_vm.$store.user.isBuyer
              ? _c(
                  "strong",
                  { staticClass: "cta__bottom-text" },
                  [
                    _vm._v("\n        Supplier? Register\n        "),
                    _c(
                      "router-link",
                      {
                        staticClass: "link",
                        attrs: { to: { name: "supplierRegister" } },
                      },
                      [_vm._v("here")]
                    ),
                    _vm._v(
                      " and we will send you business\n        proposals from brands we work with\n      "
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.$store.user.loggedIn && _vm.$store.user.isBuyer
              ? _c("strong", { staticClass: "cta__bottom-text" }, [
                  _vm._v(
                    "\n        We work with some of the most well-known brands around the world\n      "
                  ),
                ])
              : _vm._e(),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("section", { staticClass: "search", attrs: { id: "search" } }, [
      _c("h2", { staticClass: "search__heading" }, [
        _vm._v("\n      Search among\n      "),
        _c("strong", [_vm._v(_vm._s(_vm.$store.suppliers.counter))]),
        _vm._v(" footwear suppliers\n    "),
      ]),
      _vm._v(" "),
      _c("h3", { staticClass: "search__subheading" }, [
        _vm._v("Select style below to start browse our supplier directory"),
      ]),
      _vm._v(" "),
      _c("ul", { staticClass: "categories" }, [
        _c(
          "li",
          { staticClass: "categories__item" },
          [
            _c(
              "router-link",
              {
                staticClass: "categories__link",
                attrs: {
                  to: {
                    name: "footwearSuppliers",
                    query: { footwearTypes: "Shoes" },
                  },
                },
              },
              [
                _c("img", {
                  staticClass: "categories__img",
                  attrs: {
                    src: require("../img/summer/footwear/shoes.jpg"),
                    alt: "",
                  },
                }),
                _vm._v(" "),
                _c("strong", { staticClass: "categories__title" }, [
                  _vm._v("Shoes"),
                ]),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "categories__item" },
          [
            _c(
              "router-link",
              {
                staticClass: "categories__link",
                attrs: {
                  to: {
                    name: "footwearSuppliers",
                    query: { footwearTypes: "Boots" },
                  },
                },
              },
              [
                _c("img", {
                  staticClass: "categories__img",
                  attrs: {
                    src: require("../img/summer/footwear/boots.jpg"),
                    alt: "",
                  },
                }),
                _vm._v(" "),
                _c("strong", { staticClass: "categories__title" }, [
                  _vm._v("Boots"),
                ]),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "categories__item" },
          [
            _c(
              "router-link",
              {
                staticClass: "categories__link",
                attrs: {
                  to: {
                    name: "footwearSuppliers",
                    query: { footwearTypes: "Sneakers" },
                  },
                },
              },
              [
                _c("img", {
                  staticClass: "categories__img",
                  attrs: {
                    src: require("../img/summer/footwear/sneakers.jpg"),
                    alt: "",
                  },
                }),
                _vm._v(" "),
                _c("strong", { staticClass: "categories__title" }, [
                  _vm._v("Sneakers"),
                ]),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "categories__item" },
          [
            _c(
              "router-link",
              {
                staticClass: "categories__link",
                attrs: {
                  to: {
                    name: "footwearSuppliers",
                    query: { footwearTypes: "Heeled Shoes" },
                  },
                },
              },
              [
                _c("img", {
                  staticClass: "categories__img",
                  attrs: {
                    src: require("../img/summer/footwear/heeledshoes.jpg"),
                    alt: "",
                  },
                }),
                _vm._v(" "),
                _c("strong", { staticClass: "categories__title" }, [
                  _vm._v("Heeled Shoes"),
                ]),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "categories__item" },
          [
            _c(
              "router-link",
              {
                staticClass: "categories__link",
                attrs: {
                  to: {
                    name: "footwearSuppliers",
                    query: { footwearTypes: "Espadrilles" },
                  },
                },
              },
              [
                _c("img", {
                  staticClass: "categories__img",
                  attrs: {
                    src: require("../img/summer/footwear/espadrilles.jpg"),
                    alt: "",
                  },
                }),
                _vm._v(" "),
                _c("strong", { staticClass: "categories__title" }, [
                  _vm._v("Espadrilles"),
                ]),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "categories__item" },
          [
            _c(
              "router-link",
              {
                staticClass: "categories__link",
                attrs: {
                  to: {
                    name: "footwearSuppliers",
                    query: { footwearTypes: "Moccasin" },
                  },
                },
              },
              [
                _c("img", {
                  staticClass: "categories__img",
                  attrs: {
                    src: require("../img/summer/footwear/moccasin.jpg"),
                    alt: "",
                  },
                }),
                _vm._v(" "),
                _c("strong", { staticClass: "categories__title" }, [
                  _vm._v("Moccasins"),
                ]),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "categories__item" },
          [
            _c(
              "router-link",
              {
                staticClass: "categories__link",
                attrs: {
                  to: {
                    name: "footwearSuppliers",
                    query: { footwearTypes: "Flip Flops" },
                  },
                },
              },
              [
                _c("img", {
                  staticClass: "categories__img",
                  attrs: {
                    src: require("../img/summer/footwear/flipflops.jpg"),
                    alt: "",
                  },
                }),
                _vm._v(" "),
                _c("strong", { staticClass: "categories__title" }, [
                  _vm._v("Flip Flops"),
                ]),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "categories__item" },
          [
            _c(
              "router-link",
              {
                staticClass: "categories__link",
                attrs: {
                  to: {
                    name: "footwearSuppliers",
                    query: { footwearTypes: "Ballet Flats" },
                  },
                },
              },
              [
                _c("img", {
                  staticClass: "categories__img",
                  attrs: {
                    src: require("../img/summer/footwear/balletflats.jpg"),
                    alt: "",
                  },
                }),
                _vm._v(" "),
                _c("strong", { staticClass: "categories__title" }, [
                  _vm._v("Ballet Flats"),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "section",
      { staticClass: "process" },
      [
        _c("div", { staticClass: "process__container" }, [
          _c("h2", { staticClass: "process__heading" }, [
            _vm._v("Get tailor-made offers from vetted suppliers"),
          ]),
          _vm._v(" "),
          _c("h3", { staticClass: "process__subheading" }, [
            _vm._v(
              "Submit your request in 5 minutes and we will handle the rest"
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "process-flow" }, [
            _c("div", { staticClass: "process-flow__step" }, [
              _c("div", {
                staticClass: "process-flow__icon process-flow__icon--large",
                domProps: { innerHTML: _vm._s(_vm.svg.iconIdea) },
              }),
              _vm._v(" "),
              _vm._m(0),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "process-flow__step" }, [
              _c("div", {
                staticClass: "process-flow__icon",
                domProps: { innerHTML: _vm._s(_vm.svg.iconSearch) },
              }),
              _vm._v(" "),
              _vm._m(1),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "process-flow__step" }, [
              _c("div", {
                staticClass: "process-flow__icon process-flow__icon--large",
                domProps: { innerHTML: _vm._s(_vm.svg.iconInvoice) },
              }),
              _vm._v(" "),
              _vm._m(2),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "process-flow__step" }, [
              _c("div", {
                staticClass: "process-flow__icon",
                domProps: { innerHTML: _vm._s(_vm.svg.iconInformation) },
              }),
              _vm._v(" "),
              _vm._m(3),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "process-flow__step" }, [
              _c("div", {
                staticClass: "process-flow__icon process-flow__icon--large",
                domProps: { innerHTML: _vm._s(_vm.svg.iconFeedback) },
              }),
              _vm._v(" "),
              _vm._m(4),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "fs-button-main",
          {
            staticClass: "process__action",
            attrs: {
              to: { name: "quotation" },
              long: true,
              size: "large",
              exact: "",
            },
          },
          [_vm._v("Submit your request")]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("section", { staticClass: "benefits" }, [
      !_vm.$store.user.loggedIn
        ? _c("h2", { staticClass: "benefits__heading" }, [
            _vm._v(
              "\n      Find better partners than possible the traditional way\n    "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.$store.user.loggedIn
        ? _c("h3", { staticClass: "benefits__subheading" }, [
            _vm._v(
              "\n      All the benefits of modern sourcing in one platform\n    "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.$store.user.loggedIn
        ? _c("div", { staticClass: "benefits__container" }, [
            _c(
              "div",
              { staticClass: "benefits__group" },
              [
                _c("h4", { staticClass: "benefits__group-heading" }, [
                  _vm._v("Buyers"),
                ]),
                _vm._v(" "),
                _c("ul", { staticClass: "benefits__list" }, [
                  _c("li", { staticClass: "benefits__item" }, [
                    _c("span", {
                      staticClass: "benefits__icon",
                      domProps: { innerHTML: _vm._s(_vm.svg.check) },
                    }),
                    _vm._v(
                      "Access to a global database of verified footwear\n            manufacturers\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "benefits__item" }, [
                    _c("span", {
                      staticClass: "benefits__icon",
                      domProps: { innerHTML: _vm._s(_vm.svg.check) },
                    }),
                    _vm._v(
                      "Industry specific search, matching and filter\n            functions\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "benefits__item" }, [
                    _c("span", {
                      staticClass: "benefits__icon",
                      domProps: { innerHTML: _vm._s(_vm.svg.check) },
                    }),
                    _vm._v(
                      "Send in a request and get detailed offers from the\n            best suppliers\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "benefits__item" }, [
                    _c("span", {
                      staticClass: "benefits__icon",
                      domProps: { innerHTML: _vm._s(_vm.svg.check) },
                    }),
                    _vm._v(
                      "Intuitive usage, secure communication with\n            responses from humans\n          "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "fs-button-main",
                  {
                    staticClass: "benefits__group-button",
                    attrs: { to: { name: "quotation" }, exact: "" },
                  },
                  [_vm._v("Free sign up")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "benefits__group" },
              [
                _c("h4", { staticClass: "benefits__group-heading" }, [
                  _vm._v("Suppliers"),
                ]),
                _vm._v(" "),
                _c("ul", { staticClass: "benefits__list" }, [
                  _c("li", { staticClass: "benefits__item" }, [
                    _c("span", {
                      staticClass: "benefits__icon",
                      domProps: { innerHTML: _vm._s(_vm.svg.check) },
                    }),
                    _vm._v(
                      "Your own digital supplier profie searchable by\n            buyers\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "benefits__item" }, [
                    _c("span", {
                      staticClass: "benefits__icon",
                      domProps: { innerHTML: _vm._s(_vm.svg.check) },
                    }),
                    _vm._v(
                      "Find new business opportunities that best match\n            your expertise\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "benefits__item" }, [
                    _c("span", {
                      staticClass: "benefits__icon",
                      domProps: { innerHTML: _vm._s(_vm.svg.check) },
                    }),
                    _vm._v(
                      "Submit offers to requests from vetted buyers\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "benefits__item" }, [
                    _c("span", {
                      staticClass: "benefits__icon",
                      domProps: { innerHTML: _vm._s(_vm.svg.check) },
                    }),
                    _vm._v(
                      "Benchmark your offers with market average for KPIs\n            such as price & leadtime\n          "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "fs-button-main",
                  {
                    staticClass: "benefits__group-button",
                    attrs: { to: { name: "supplierRegister" }, exact: "" },
                  },
                  [_vm._v("Free sign up")]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("section", { staticClass: "partners" }, [
        _c(
          "h2",
          {
            staticClass: "partners__heading",
            class: { "partners__heading--big": _vm.$store.user.loggedIn },
          },
          [
            _vm._v(
              "\n        Be part of a modern, open and connected ecosystem\n      "
            ),
          ]
        ),
        _vm._v(" "),
        _c("h3", { staticClass: "partners__subheading" }, [
          _vm._v(
            "We have partnered up with some of the top players in the industry"
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "partners__container" }, [
          _c("div", { staticClass: "partners__partner" }, [
            _c(
              "a",
              {
                staticClass: "partners__image-link",
                attrs: {
                  href: "https://fdra.org/",
                  target: "_blank",
                  rel: "noopener noreferrer",
                },
                on: {
                  click: function ($event) {
                    return _vm.$ga.event("partners", "FDRA click")
                  },
                },
              },
              [
                _c("img", {
                  staticClass: "partners__image",
                  attrs: { src: require("../img/partner-logos/fdra-mono.png") },
                }),
                _vm._v(" "),
                _c("img", {
                  staticClass: "partners__image partners__image--on-top",
                  attrs: { src: require("../img/partner-logos/fdra.png") },
                }),
              ]
            ),
            _vm._v(" "),
            _c("p", { staticClass: "partners__text" }, [
              _vm._v(
                "\n            FDRA - Footwear Distributors and Retailers of America, is by far the largest footwear association in North\n            America, representing 90%+ of brands, importers and suppliers. Every year, FDRA organizes the Global Shoe\n            Sourcing event, in which FindSourcing is a speaker and partner. Click\n            "
              ),
              _c(
                "a",
                {
                  staticClass: "link link--dark",
                  attrs: {
                    href: "https://www.shoesourcingsummit.com/",
                    target: "_blank",
                    rel: "noopener noreferrer",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$ga.event("partners", "FDRA click")
                    },
                  },
                },
                [_vm._v("here")]
              ),
              _vm._v("\n            to get to the event website.\n          "),
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "partners__link link link--button-like link--dark",
                attrs: {
                  href: "https://fdra.org/",
                  target: "_blank",
                  rel: "noopener noreferrer",
                },
                on: {
                  click: function ($event) {
                    return _vm.$ga.event("partners", "FDRA click")
                  },
                },
              },
              [_vm._v("Visit FDRA")]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "partners__partner" }, [
            _c(
              "a",
              {
                staticClass: "partners__image-link",
                attrs: {
                  href: "https://www.apiccaps.pt/",
                  target: "_blank",
                  rel: "noopener noreferrer",
                },
                on: {
                  click: function ($event) {
                    return _vm.$ga.event("partners", "APICCAPS click")
                  },
                },
              },
              [
                _c("img", {
                  staticClass: "partners__image",
                  attrs: {
                    src: require("../img/partner-logos/apiccaps-mono.png"),
                  },
                }),
                _vm._v(" "),
                _c("img", {
                  staticClass: "partners__image partners__image--on-top",
                  attrs: { src: require("../img/partner-logos/apiccaps.png") },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              { staticClass: "partners__text" },
              [
                _vm._v(
                  "\n            APICCAPS - the Portuguese Footwear, Components, Leather Good Manufacturers' Association - represents some\n            of the very best European manufacturers out there. We're very happy to officially be able to promote these\n            manufacturers and strengthen our European expertise further. Click\n            "
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "link link--dark",
                    attrs: {
                      to: {
                        name: "footwearSuppliers",
                        query: { associations: "APICCAPS (Portugal)" },
                      },
                    },
                  },
                  [_vm._v("here")]
                ),
                _vm._v(
                  "\n            to find a list of APICCAPS' members!\n          "
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "partners__link link link--button-like link--dark",
                attrs: {
                  href: "https://www.apiccaps.pt/",
                  target: "_blank",
                  rel: "noopener noreferrer",
                },
                on: {
                  click: function ($event) {
                    return _vm.$ga.event("partners", "APICCAPS click")
                  },
                },
              },
              [_vm._v("Visit APICCAPS")]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "partners__partner" }, [
            _c(
              "a",
              {
                staticClass: "partners__image-link",
                attrs: {
                  href: "http://www.lefaso.org.vn/default.aspx?ID1=2",
                  target: "_blank",
                  rel: "noopener noreferrer",
                },
                on: {
                  click: function ($event) {
                    return _vm.$ga.event("partners", "Lefaso click")
                  },
                },
              },
              [
                _c("img", {
                  staticClass: "partners__image",
                  attrs: {
                    src: require("../img/partner-logos/lefaso-mono.png"),
                  },
                }),
                _vm._v(" "),
                _c("img", {
                  staticClass: "partners__image partners__image--on-top",
                  attrs: { src: require("../img/partner-logos/lefaso.png") },
                }),
              ]
            ),
            _vm._v(" "),
            _c("p", { staticClass: "partners__text" }, [
              _vm._v(
                "\n            We've teamed up with LEFASO, the Vietnam Leather, Footwear and Handbag Association to be able provide\n            brands with the best Vietnamese manufacturers around. Vietnam is one of the rising stars in footwear\n            manufacturing and we are eager to be able to promote LEFASO's manufacturers and find them great\n            opportunities for growth!"
              ),
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "partners__link link link--button-like link--dark",
                attrs: {
                  href: "http://www.lefaso.org.vn/default.aspx?ID1=2",
                  target: "_blank",
                  rel: "noopener noreferrer",
                },
                on: {
                  click: function ($event) {
                    return _vm.$ga.event("partners", "Lefaso click")
                  },
                },
              },
              [_vm._v("Visit LEFASO")]
            ),
          ]),
          _vm._v(" "),
          _vm.$store.general.satra
            ? _c("div", { staticClass: "partners__partner" }, [
                _c(
                  "a",
                  {
                    staticClass: "partners__image-link",
                    attrs: {
                      href: "https://www.satra.com/",
                      target: "_blank",
                      rel: "noopener noreferrer",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$ga.event("partners", "SATRA click")
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "partners__image",
                      attrs: {
                        src: require("../img/partner-logos/satra-mono.png"),
                      },
                    }),
                    _vm._v(" "),
                    _c("img", {
                      staticClass: "partners__image partners__image--on-top",
                      attrs: { src: require("../img/partner-logos/satra.png") },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  { staticClass: "partners__text" },
                  [
                    _vm._v(
                      "\n            SATRA is a leading technical authority for footwear and leather. SATRA members get exclusive access to\n            SATRA test methods, accreditation, consultancy, research facilities, technical training and factory\n            production management systems. At FindSourcing, we utilize SATRA's knowledge to help you find the best\n            supplier! Click\n            "
                    ),
                    _c(
                      "router-link",
                      {
                        staticClass: "link link--dark",
                        attrs: {
                          to: {
                            name: "footwearSuppliers",
                            query: { associations: "SATRA" },
                          },
                        },
                      },
                      [_vm._v("here")]
                    ),
                    _vm._v(
                      "\n            to find a list of SATRA's members!\n          "
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass:
                      "partners__link link link--button-like link--dark",
                    attrs: {
                      href: "https://www.satra.com/",
                      target: "_blank",
                      rel: "noopener noreferrer",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$ga.event("partners", "SATRA click")
                      },
                    },
                  },
                  [_vm._v("Visit SATRA")]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "partners__partner" }, [
            _c(
              "a",
              {
                staticClass: "partners__image-link",
                attrs: {
                  href: "https://sourcingplayground.com/",
                  target: "_blank",
                  rel: "noopener noreferrer",
                },
                on: {
                  click: function ($event) {
                    return _vm.$ga.event(
                      "partners",
                      "Sourcing Playground click"
                    )
                  },
                },
              },
              [
                _c("img", {
                  staticClass: "partners__image",
                  attrs: {
                    src: require("../img/partner-logos/sourcing-playground-mono.png"),
                  },
                }),
                _vm._v(" "),
                _c("img", {
                  staticClass: "partners__image partners__image--on-top",
                  attrs: {
                    src: require("../img/partner-logos/sourcing-playground.png"),
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c("p", { staticClass: "partners__text" }, [
              _vm._v(
                "\n            At Sourcing Playground you can connect with trusted manufacturers and suppliers. To give both our users\n            the best possible experience, FindSourcing handles any footwear related request on Sourcing Playground\n            while we recommend our users to try Sourcing Playground for any non-footwear related request.\n          "
              ),
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "partners__link link link--button-like link--dark",
                attrs: {
                  href: "https://sourcingplayground.com/",
                  target: "_blank",
                  rel: "noopener noreferrer",
                },
                on: {
                  click: function ($event) {
                    return _vm.$ga.event(
                      "partners",
                      "Sourcing Playground click"
                    )
                  },
                },
              },
              [_vm._v("Visit Sourcing Playground")]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "partners__partner" }, [
            _c(
              "a",
              {
                staticClass: "partners__image-link",
                attrs: {
                  href: "https://www.ciceg.org",
                  target: "_blank",
                  rel: "noopener noreferrer",
                },
                on: {
                  click: function ($event) {
                    return _vm.$ga.event("partners", "CICEG click")
                  },
                },
              },
              [
                _c("img", {
                  staticClass: "partners__image",
                  attrs: {
                    src: require("../img/partner-logos/ciceg-mono.png"),
                  },
                }),
                _vm._v(" "),
                _c("img", {
                  staticClass: "partners__image partners__image--on-top",
                  attrs: { src: require("../img/partner-logos/ciceg.png") },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "p",
              { staticClass: "partners__text" },
              [
                _vm._v(
                  "\n            CICEG and Shoes from Mexico is the industry organization in Mexico to bring all the amazing suppliers in\n            the region closer to you. Mexico has gotten plenty of attention lately and we are getting more and more\n            requests for made in Mexico, for a reason! Check out the suppliers connected to CICEG\n            "
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "link link--dark",
                    attrs: {
                      to: {
                        name: "footwearSuppliers",
                        query: { associations: "CICEG (Mexico)" },
                      },
                    },
                  },
                  [_vm._v("here")]
                ),
                _vm._v(".\n          "),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "partners__link link link--button-like link--dark",
                attrs: {
                  href: "https://www.ciceg.org",
                  target: "_blank",
                  rel: "noopener noreferrer",
                },
                on: {
                  click: function ($event) {
                    return _vm.$ga.event("partners", "CICEG click")
                  },
                },
              },
              [_vm._v("\n            Visit CICEG\n          ")]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "partners__partner" }, [
            _c(
              "a",
              {
                staticClass: "partners__image-link",
                attrs: {
                  href: "http://www.globalfootwear.cn",
                  target: "_blank",
                  rel: "noopener noreferrer",
                },
                on: {
                  click: function ($event) {
                    return _vm.$ga.event("partners", "GFSS click")
                  },
                },
              },
              [
                _c("img", {
                  staticClass: "partners__image",
                  attrs: { src: require("../img/partner-logos/gfss-mono.png") },
                }),
                _vm._v(" "),
                _c("img", {
                  staticClass: "partners__image partners__image--on-top",
                  attrs: { src: require("../img/partner-logos/gfss.png") },
                }),
              ]
            ),
            _vm._v(" "),
            _c("p", { staticClass: "partners__text" }, [
              _vm._v(
                "\n            To stay on top of the latest within sustainability is key to be a leader in today's environment. GFSS, the\n            Global Footwear Sustainability Summit, attracts hundreds of top officers and industry leaders from leading\n            footwear brands, OEMs, sourcing & retailing groups, materials suppliers as well as relevant government\n            officials and associations.\n          "
              ),
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "partners__link link link--button-like link--dark",
                attrs: {
                  href: "http://www.globalfootwear.cn",
                  target: "_blank",
                  rel: "noopener noreferrer",
                },
                on: {
                  click: function ($event) {
                    return _vm.$ga.event("partners", "GFSS click")
                  },
                },
              },
              [_vm._v("\n            Visit GFSS\n          ")]
            ),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _vm.isMounted
      ? _c(
          "section",
          { staticClass: "knowledge-bank" },
          [
            _c("h2", { staticClass: "knowledge-bank__heading" }, [
              _vm._v("Dive into our knowledge bank"),
            ]),
            _vm._v(" "),
            _c("h3", { staticClass: "knowledge-bank__subheading" }, [
              _vm._v(
                "\n      All the critical knowledge you need to become a footwear production expert\n    "
              ),
            ]),
            _vm._v(" "),
            _vm.isMounted
              ? _c(
                  "div",
                  [
                    _c(
                      "Swiper",
                      {
                        ref: "articlesCarousel",
                        attrs: { options: _vm.swiperOptions },
                      },
                      [
                        _vm._l(
                          _vm.carouselFeaturedArticles,
                          function (article) {
                            return _c("Swiper-slide", { key: article.id }, [
                              _c("div", { staticClass: "slide__content" }, [
                                _c(
                                  "div",
                                  { staticClass: "slide__image-container" },
                                  [
                                    _c("img", {
                                      staticClass: "slide__image",
                                      attrs: {
                                        src:
                                          _vm.$blobUrl +
                                          "/images/" +
                                          article.previewImg,
                                        alt: article.title,
                                      },
                                    }),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "slide__info" },
                                  [
                                    _c(
                                      "h5",
                                      { staticClass: "slide__heading" },
                                      [_vm._v(_vm._s(article.title))]
                                    ),
                                    _vm._v(" "),
                                    _c("p", { staticClass: "slide__intro" }, [
                                      _vm._v(_vm._s(article.description)),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "router-link",
                                      {
                                        key: article.id,
                                        staticClass:
                                          "link link--dark link--button-like slide__link",
                                        attrs: {
                                          to: {
                                            name: "article",
                                            params: {
                                              category: article.categorySlug,
                                              slug: article.slug,
                                            },
                                          },
                                        },
                                      },
                                      [_vm._v("Read more")]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ])
                          }
                        ),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "swiper-button-prev",
                          attrs: { slot: "button-prev" },
                          slot: "button-prev",
                        }),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "swiper-button-next",
                          attrs: { slot: "button-next" },
                          slot: "button-next",
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.$store.knowledgebank.featured, function (category) {
              return category.articles.length > 3
                ? _c("div", { staticClass: "category" }, [
                    _c("h4", { staticClass: "category__heading" }, [
                      _vm._v(_vm._s(category.title)),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "category__articles" },
                      _vm._l(category.articles.slice(0, 4), function (article) {
                        return _c(
                          "div",
                          { staticClass: "item" },
                          [
                            _c("div", {
                              staticClass: "item__image",
                              style: {
                                backgroundImage:
                                  "url(" +
                                  _vm.$blobUrl +
                                  "/images/" +
                                  article.previewImg +
                                  ")",
                              },
                            }),
                            _vm._v(" "),
                            _c("h5", { staticClass: "item__heading" }, [
                              _vm._v(_vm._s(article.title)),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "item__intro" }, [
                              _vm._v(_vm._s(article.description)),
                            ]),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                key: article.id,
                                staticClass:
                                  "link link--dark link--button-like item__link",
                                attrs: {
                                  to: {
                                    name: "article",
                                    params: {
                                      category: category.slug,
                                      slug: article.slug,
                                    },
                                  },
                                },
                              },
                              [_vm._v("Read more")]
                            ),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ])
                : _vm._e()
            }),
            _vm._v(" "),
            _c(
              "fs-button-main",
              {
                staticClass: "knowledge-bank__cta",
                attrs: {
                  long: true,
                  size: "large",
                  to: { name: "articles" },
                  exact: "",
                },
              },
              [_vm._v("View all articles")]
            ),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "process-flow__info" }, [
      _c("strong", { staticClass: "process-flow__title" }, [
        _vm._v("Submit to FindSourcing"),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "process-flow__text" }, [
        _vm._v("Brand registers quotation request on FindSourcing.com"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "process-flow__info" }, [
      _c("strong", { staticClass: "process-flow__title" }, [
        _vm._v("Global sourcing"),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "process-flow__text" }, [
        _vm._v(
          "\n              FindSourcing initiates sourcing among suppliers and contacts according to request.\n            "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "process-flow__info" }, [
      _c("strong", { staticClass: "process-flow__title" }, [
        _vm._v("Suppliers submit offers"),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "process-flow__text" }, [
        _vm._v("Suppliers submit their offers."),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "process-flow__info" }, [
      _c("strong", { staticClass: "process-flow__title" }, [
        _vm._v("Offer review"),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "process-flow__text" }, [
        _vm._v(
          "\n              FindSourcing reviews and curates offers. Only the best are selected and presented to the Buyer.\n            "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "process-flow__info" }, [
      _c("strong", { staticClass: "process-flow__title" }, [
        _vm._v("Buyer selection"),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "process-flow__text" }, [
        _vm._v(
          "\n              Buyer selects suppliers to proceed with. Communication is handed over, buyer – manufacturer.\n            "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }